const WarningLogsIcon = (props: any) => {
  return (
    <svg
      width={props.width || "14"}
      height={props.height || "14"}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.09589 6.27754C4.79827 3.25918 5.64946 1.75 6.9974 1.75C8.34533 1.75 9.19652 3.25918 10.8989 6.27753L11.111 6.65365C12.5257 9.16189 13.233 10.416 12.5937 11.333C11.9545 12.25 10.3728 12.25 7.20953 12.25H6.78526C3.62197 12.25 2.04033 12.25 1.40105 11.333C0.761762 10.416 1.46909 9.16189 2.88376 6.65366L3.09589 6.27754ZM6.9974 4.22917C7.23902 4.22917 7.4349 4.42504 7.4349 4.66667V7.58333C7.4349 7.82496 7.23902 8.02083 6.9974 8.02083C6.75577 8.02083 6.5599 7.82496 6.5599 7.58333V4.66667C6.5599 4.42504 6.75577 4.22917 6.9974 4.22917ZM6.9974 9.91667C7.31956 9.91667 7.58073 9.6555 7.58073 9.33333C7.58073 9.01117 7.31956 8.75 6.9974 8.75C6.67523 8.75 6.41406 9.01117 6.41406 9.33333C6.41406 9.6555 6.67523 9.91667 6.9974 9.91667Z"
        fill="#EF9300"
      />
    </svg>
  );
};

export { WarningLogsIcon };
