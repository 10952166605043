const GoToMaterialsIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8989 7.19178L16.3919 4.09709M16.3919 4.09709L13.098 3.89796M16.3919 4.09709L16.1927 7.3909" stroke="#ED028C" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12.8125 10.5524L7.49998 13.5038M7.49998 13.5038L2.18748 10.5524M7.49998 13.5038L7.5 19.4413M13.125 16.0404V10.9672C13.125 10.753 13.125 10.646 13.0934 10.5505C13.0655 10.466 13.0199 10.3884 12.9596 10.323C12.8914 10.249 12.7978 10.197 12.6106 10.093L7.98564 7.52358C7.80839 7.4251 7.71977 7.37587 7.62591 7.35656C7.54284 7.33948 7.45716 7.33948 7.37409 7.35656C7.28024 7.37587 7.19161 7.4251 7.01436 7.52358L2.38936 10.093C2.20215 10.197 2.10855 10.249 2.0404 10.323C1.9801 10.3884 1.93447 10.466 1.90655 10.5505C1.875 10.646 1.875 10.753 1.875 10.9672V16.0404C1.875 16.2545 1.875 16.3616 1.90655 16.4571C1.93447 16.5416 1.9801 16.6191 2.0404 16.6846C2.10855 16.7585 2.20215 16.8105 2.38936 16.9145L7.01436 19.484C7.19161 19.5824 7.28024 19.6317 7.37409 19.651C7.45716 19.6681 7.54284 19.6681 7.62591 19.651C7.71977 19.6317 7.80839 19.5824 7.98564 19.484L12.6106 16.9145C12.7978 16.8105 12.8914 16.7585 12.9596 16.6846C13.0199 16.6191 13.0655 16.5416 13.0934 16.4571C13.125 16.3616 13.125 16.2545 13.125 16.0404Z" stroke="#ED028C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10.3125 11.9375L4.6875 8.8125" stroke="#ED028C" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export { GoToMaterialsIcon };
