import { IIconProps } from "@/components/icons/interface";

const GoMakeCurrency = ({ width, height, color }: IIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width={width || 24}
      height={height || 24}
      viewBox="0 0 101.563 95.2401"
    >
      <defs>
        <clipPath id="clip_0">
          <path
            transform="matrix(1,0,0,-1,0,95.2401)"
            d="M0 95.24H101.563V0H0Z"
          />
        </clipPath>
      </defs>
      <g id="layer_1" data-name=""></g>
      <g id="layer_1" data-name="">
        <g clip-path="url(#clip_0)">
          <path
            transform="matrix(1,0,0,-1,92.8568,47.619798)"
            stroke-width="6"
            stroke-linecap="butt"
            stroke-miterlimit="10"
            stroke-linejoin="miter"
            fill="none"
            stroke={color || "#000000"}
            d="M0 0C0 23.237-18.838 42.074-42.075 42.074-65.313 42.074-84.15 23.237-84.15 0-84.15-23.237-65.313-42.075-42.075-42.075-18.838-42.075 0-23.237 0 0Z"
          />
          <path
            transform="matrix(1,0,0,-1,51.9705,64.312198)"
            fill={color || "#000000"}
            d="M0 0C-2.362 0-4.569 .419-6.621 1.259-8.672 2.129-10.475 3.326-12.03 4.849-13.584 6.372-14.796 8.144-15.667 10.164-16.506 12.216-16.925 14.423-16.925 16.785-16.925 19.117-16.506 21.293-15.667 23.313-14.796 25.334-13.599 27.09-12.076 28.582-10.522 30.105-8.719 31.286-6.667 32.126-4.616 32.965-2.409 33.385-.046 33.385 1.601 33.385 3.202 33.151 4.756 32.686 6.341 32.219 7.771 31.566 9.046 30.727 10.32 29.888 11.362 28.924 12.17 27.836L8.906 24.433C7.974 25.334 7.01 26.08 6.015 26.67 5.051 27.261 4.057 27.712 3.031 28.022 2.005 28.333 .979 28.488-.046 28.488-1.725 28.488-3.279 28.193-4.709 27.603-6.108 27.012-7.352 26.188-8.439 25.132-9.496 24.074-10.32 22.831-10.911 21.401-11.501 20.003-11.796 18.464-11.796 16.785-11.796 15.169-11.485 13.63-10.864 12.169-10.242 10.739-9.387 9.48-8.299 8.393-7.18 7.305-5.906 6.45-4.476 5.828-3.015 5.206-1.477 4.896 .14 4.896 1.353 4.896 2.502 5.066 3.59 5.408 4.678 5.781 5.642 6.294 6.481 6.947 7.321 7.6 7.974 8.346 8.439 9.186 8.906 10.056 9.139 10.973 9.139 11.937V12.869L9.838 12.216H-.14V17.205H14.314C14.377 16.863 14.423 16.521 14.455 16.18 14.486 15.868 14.501 15.558 14.501 15.247 14.532 14.967 14.548 14.688 14.548 14.407 14.548 12.263 14.175 10.305 13.429 8.532 12.683 6.792 11.642 5.284 10.305 4.01 8.999 2.735 7.46 1.74 5.688 1.025 3.948 .342 2.052 0 0 0"
          />
          <path
            transform="matrix(1,0,0,-1,50.7816,34.222299)"
            stroke-width="4"
            stroke-linecap="butt"
            stroke-miterlimit="10"
            stroke-linejoin="miter"
            fill="none"
            stroke={color || "#000000"}
            d="M0 0V9.22"
          />
          <path
            transform="matrix(1,0,0,-1,50.7816,71.0358)"
            stroke-width="4"
            stroke-linecap="butt"
            stroke-miterlimit="10"
            stroke-linejoin="miter"
            fill="none"
            stroke={color || "#000000"}
            d="M0 0V9.22"
          />
        </g>
      </g>
    </svg>
  );
};

export { GoMakeCurrency };
