const HandQuickSetupIcon = (props) => {
  return (
    <svg
      width={props.width || "196"}
      height={props.height || "200"}
      viewBox="0 0 196 200"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.0215 118.052C62.0215 163.311 98.6472 200.038 143.826 200C192.864 199.958 198.983 156.582 193.767 110.608C188.664 65.6393 189.006 36.1018 143.826 36.1018C98.646 36.1018 62.0215 72.7941 62.0215 118.052Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M75.2107 123.487C85.774 117.994 95.2254 110.561 103.911 101.764C103.649 95.9381 103.109 93.8081 101.157 88.5729C98.03 80.1879 97.2899 76.3463 100.343 66.9699C102.154 61.4117 104.837 56.5651 103.339 55.0527C99.2247 50.9017 89.9055 59.5181 83.6947 76.4453C82.1837 74.1656 80.1118 70.9923 79.903 70.4787C73.5033 54.7495 67.4716 41.1256 63.6466 36.5673C59.5275 31.6638 53.7628 33.9906 56.2523 41.5179C61.041 55.9995 66.592 72.4391 66.592 72.4391C53.5206 57.1134 43.0512 42.06 37.7139 45.5254C32.7955 48.7197 39.32 55.674 46.9689 65.4823C52.0492 71.9935 56.3733 77.2547 58.4897 79.7152C48.5948 71.4737 39.0556 63.7496 33.4342 59.0849C27.7127 54.3337 21.3352 60.0565 27.0678 65.6816C30.9311 69.4687 45.8359 81.9318 51.7255 86.7586C50.9459 87.4294 50.3244 88.266 49.8723 88.9182C38.921 82.6236 33.2402 79.4973 29.3633 77.2683C21.0955 72.5158 19.4795 79.5889 22.6917 82.2597C23.6962 83.0951 42.237 96.7537 52.4593 103.365C60.291 112.479 66.1966 118.271 75.2107 123.487Z"
        fill="#FE9F93"
      />
      <path
        d="M91.2822 83.0047C91.1526 83.0042 91.0283 82.9526 90.9363 82.8611C87.1693 79.0579 84.5946 77.2757 83.5333 76.9056C83.4729 76.8845 83.4173 76.8517 83.3696 76.809C83.3219 76.7664 83.2831 76.7147 83.2553 76.657C83.2276 76.5993 83.2114 76.5367 83.2078 76.4728C83.2042 76.4088 83.2132 76.3448 83.2343 76.2843C83.2554 76.2239 83.2882 76.1682 83.3307 76.1204C83.3733 76.0726 83.4249 76.0337 83.4825 76.0059C83.5401 75.9781 83.6026 75.9619 83.6664 75.9583C83.7302 75.9547 83.7941 75.9637 83.8545 75.9848C85.4273 76.5331 88.3331 78.8475 91.6281 82.173C91.6962 82.2412 91.7426 82.3281 91.7613 82.4227C91.7799 82.5174 91.7701 82.6154 91.733 82.7044C91.696 82.7934 91.6333 82.8694 91.553 82.9226C91.4727 82.9759 91.3785 83.004 91.2822 83.0035V83.0047Z"
        fill="#2E3092"
      />
      <path
        d="M51.6113 94.9379H51.5693C51.4407 94.9269 51.3217 94.8652 51.2386 94.7663C51.1554 94.6674 51.1148 94.5395 51.1258 94.4107C51.2728 92.6867 51.7559 91.0146 54.2738 90.4503C54.3362 90.4361 54.4007 90.4344 54.4638 90.4453C54.5268 90.4561 54.5871 90.4793 54.6412 90.5135C54.6953 90.5477 54.7421 90.5922 54.779 90.6445C54.8159 90.6969 54.8422 90.756 54.8563 90.8185C54.8704 90.881 54.8721 90.9456 54.8613 91.0088C54.8504 91.0719 54.8273 91.1323 54.7932 91.1865C54.759 91.2407 54.7146 91.2876 54.6624 91.3246C54.6101 91.3616 54.5511 91.3879 54.4888 91.402C52.4823 91.8562 52.2179 93.0741 52.0969 94.4961C52.0839 94.6165 52.0273 94.7279 51.9378 94.8094C51.8484 94.8908 51.7322 94.9365 51.6113 94.9379Z"
        fill="#2E3092"
      />
      <path
        d="M58.5782 88.532C58.5156 88.5316 58.4536 88.5194 58.3954 88.4961C58.2769 88.4462 58.1824 88.352 58.1321 88.2334C58.0818 88.1149 58.0797 87.9814 58.126 87.8612C58.7574 86.2944 59.8224 85.5976 60.6044 85.2894C61.3618 84.9899 62.129 84.959 62.4329 85.1013C62.5442 85.1557 62.6307 85.2505 62.6746 85.3665C62.7186 85.4825 62.7168 85.6109 62.6695 85.7256C62.6223 85.8403 62.5332 85.9326 62.4203 85.9838C62.3075 86.0349 62.1795 86.0411 62.0623 86.0011C61.9387 85.9936 59.962 85.9157 59.0316 88.2288C58.9941 88.3183 58.9311 88.3948 58.8505 88.4487C58.7698 88.5026 58.6752 88.5316 58.5782 88.532Z"
        fill="#2E3092"
      />
      <path
        d="M65.2628 83.9119C65.1838 83.9122 65.1058 83.8932 65.0358 83.8565C64.9657 83.8198 64.9057 83.7664 64.8609 83.7012C64.8161 83.6359 64.7879 83.5607 64.7787 83.482C64.7695 83.4034 64.7796 83.3237 64.8082 83.2498C65.314 81.9279 66.3213 80.86 67.6103 80.2795C67.9278 80.1396 68.5097 79.9255 68.8457 80.0579C68.9595 80.1018 69.0528 80.1869 69.1069 80.2964C69.1611 80.4058 69.1723 80.5317 69.1383 80.649C69.1042 80.7663 69.0275 80.8666 68.9232 80.9299C68.819 80.9933 68.6947 81.0151 68.5752 80.9911C68.2811 81.0196 66.4835 81.6099 65.7175 83.6038C65.6806 83.6944 65.6177 83.7721 65.5368 83.8269C65.4559 83.8818 65.3606 83.9113 65.2628 83.9119Z"
        fill="#2E3092"
      />
      <path
        d="M72.6327 77.8265C72.5731 77.8268 72.514 77.8159 72.4585 77.7943C72.3388 77.7465 72.2425 77.6537 72.1902 77.5358C72.1378 77.4179 72.1334 77.2842 72.178 77.1631C72.6848 75.8418 73.6918 74.7743 74.9801 74.1928C75.2964 74.0529 75.8758 73.8388 76.2156 73.9713C76.3289 74.0154 76.4217 74.1004 76.4756 74.2096C76.5296 74.3188 76.5407 74.4442 76.507 74.5612C76.4732 74.6783 76.3969 74.7784 76.2931 74.8419C76.1894 74.9054 76.0656 74.9277 75.9462 74.9044C75.6522 74.9329 73.8546 75.5233 73.0873 77.5171C73.0515 77.6085 72.9889 77.687 72.9078 77.7422C72.8266 77.7974 72.7308 77.8268 72.6327 77.8265Z"
        fill="#2E3092"
      />
      <path
        d="M81.3021 76.8127C81.2227 76.8128 81.1445 76.7935 81.0743 76.7564C81.0041 76.7193 80.944 76.6655 80.8993 76.5998C78.8645 73.6295 76.7901 70.6592 76.7691 70.6282C76.7464 70.5966 76.7281 70.5621 76.7147 70.5255C76.6468 70.351 69.8714 52.951 66.5097 45.4014C63.3172 38.2355 60.2211 36.0276 58.4025 36.3555C58.3395 36.3667 58.275 36.3653 58.2126 36.3514C58.1502 36.3376 58.0911 36.3115 58.0387 36.2748C57.9863 36.2381 57.9417 36.1914 57.9073 36.1374C57.873 36.0834 57.8496 36.0232 57.8385 35.9601C57.8274 35.897 57.8288 35.8324 57.8426 35.7699C57.8564 35.7074 57.8824 35.6482 57.919 35.5957C57.9557 35.5433 58.0023 35.4985 58.0562 35.4641C58.1101 35.4297 58.1703 35.4063 58.2332 35.3951C59.5922 35.1538 63.2122 35.6055 67.3992 45.0029C70.6757 52.352 77.1768 69.0304 77.6006 70.1171C77.8773 70.5131 79.8071 73.2805 81.7023 76.0491C81.752 76.1223 81.7809 76.2077 81.7859 76.2962C81.791 76.3846 81.7719 76.4727 81.7308 76.5511C81.6897 76.6296 81.6281 76.6953 81.5526 76.7414C81.4771 76.7875 81.3905 76.8121 81.3021 76.8127Z"
        fill="#FCFAFF"
      />
      <path
        d="M64.3598 72.9303C64.289 72.9298 64.2191 72.9139 64.1551 72.8835C64.0911 72.8532 64.0344 72.8091 63.9892 72.7545C58.2763 65.851 48.8793 54.7852 46.8333 52.8285C43.243 49.4052 40.3261 47.5587 38.8583 47.7542C38.7948 47.7636 38.7301 47.7602 38.668 47.7441C38.6059 47.728 38.5476 47.6997 38.4966 47.6607C38.4456 47.6217 38.4029 47.5729 38.371 47.5171C38.3391 47.4613 38.3187 47.3997 38.311 47.3359C38.2938 47.2079 38.3279 47.0783 38.406 46.9755C38.4841 46.8727 38.5996 46.8051 38.7274 46.7876C40.5806 46.5314 43.5321 48.3322 47.4993 52.1194C49.5724 54.0996 59.0053 65.2024 64.7342 72.1233C64.7933 72.1942 64.831 72.2804 64.843 72.372C64.855 72.4635 64.8407 72.5566 64.8019 72.6403C64.763 72.7241 64.7011 72.795 64.6235 72.8448C64.5459 72.8946 64.4557 72.9212 64.3635 72.9216L64.3598 72.9303Z"
        fill="#FCFAFF"
      />
      <path
        d="M86.6952 75.0319C86.6379 75.0317 86.5811 75.0217 86.5272 75.0022C86.4083 74.9563 86.3118 74.8661 86.2577 74.7506C86.2036 74.6351 86.1961 74.5031 86.2369 74.3822C88.0295 69.5046 94.48 53.7334 102.158 55.5206C102.221 55.535 102.28 55.5616 102.332 55.5989C102.384 55.6361 102.428 55.6833 102.462 55.7376C102.496 55.792 102.518 55.8525 102.529 55.9157C102.539 55.9789 102.537 56.0435 102.523 56.106C102.508 56.1684 102.482 56.2273 102.445 56.2794C102.408 56.3316 102.36 56.3758 102.306 56.4098C102.252 56.4437 102.192 56.4665 102.128 56.477C102.065 56.4876 102.001 56.4855 101.939 56.4711C96.4468 55.1938 90.6957 65.059 87.1511 74.7188C87.1158 74.8112 87.0533 74.8906 86.9718 74.9465C86.8904 75.0024 86.7939 75.0322 86.6952 75.0319Z"
        fill="#FCFAFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.798 104.828C136.951 119.4 170.451 119.27 193.765 110.026C193.765 110.026 195.818 129.007 195.762 135.359C195.718 140.558 197.074 152.059 193.765 156.068C171.39 183.172 121.554 166.721 81.0898 125.241C81.0898 125.249 96.5407 118.776 106.798 104.828Z"
        fill="#ED028C"
      />
      <path
        d="M12.6913 86.6063C12.5954 86.6055 12.5018 86.5767 12.422 86.5235C12.3421 86.4704 12.2794 86.3951 12.2416 86.3068C12.1613 86.115 4.39998 66.8957 19.5309 48.9809C19.5709 48.928 19.6212 48.8839 19.6787 48.8512C19.7363 48.8186 19.7999 48.798 19.8657 48.7908C19.9315 48.7835 19.998 48.7898 20.0613 48.8092C20.1246 48.8286 20.1832 48.8607 20.2337 48.9036C20.2841 48.9465 20.3253 48.9992 20.3548 49.0586C20.3842 49.118 20.4012 49.1827 20.4048 49.2489C20.4084 49.3151 20.3985 49.3813 20.3757 49.4436C20.3529 49.5058 20.3177 49.5627 20.2722 49.6108C5.52427 67.0801 13.0619 85.7437 13.1398 85.9306C13.1704 86.0047 13.1823 86.0851 13.1745 86.1649C13.1668 86.2447 13.1395 86.3214 13.0952 86.3881C13.0509 86.4549 12.9909 86.5097 12.9204 86.5478C12.85 86.5858 12.7713 86.6059 12.6913 86.6063Z"
        fill="#2E3092"
      />
      <path
        d="M5.78094 80.1581C5.71347 80.1578 5.64677 80.1438 5.58494 80.1167C5.52311 80.0897 5.46746 80.0503 5.42141 80.0009C5.12119 79.6717 -1.92103 71.7446 0.509151 54.5327C0.518156 54.4693 0.539551 54.4082 0.572116 54.353C0.604681 54.2978 0.647777 54.2496 0.698945 54.2111C0.750112 54.1726 0.808348 54.1446 0.870328 54.1286C0.932308 54.1127 0.996818 54.1091 1.06017 54.1181C1.12353 54.1272 1.18449 54.1486 1.23958 54.1812C1.29467 54.2138 1.34281 54.257 1.38124 54.3083C1.41967 54.3595 1.44765 54.4179 1.46358 54.4799C1.4795 54.542 1.48306 54.6067 1.47406 54.6701C-0.893116 71.429 6.07004 79.2645 6.14046 79.3412C6.20425 79.411 6.24637 79.4979 6.2617 79.5913C6.27702 79.6847 6.26489 79.7806 6.22677 79.8672C6.18865 79.9538 6.12621 80.0274 6.04704 80.079C5.96788 80.1307 5.87542 80.1581 5.78094 80.1581Z"
        fill="#2E3092"
      />
      <path
        d="M88.4058 37.6787C88.3818 37.6785 88.3578 37.6764 88.3342 37.6725C88.2066 37.6536 88.0917 37.5847 88.0148 37.4809C87.9379 37.3772 87.9052 37.2472 87.924 37.1193C87.9326 37.0624 88.7406 31.3655 86.6885 25.1786C83.947 16.9285 77.6732 11.7576 68.0402 9.79349C67.9775 9.78081 67.9179 9.75589 67.8648 9.72013C67.8117 9.68438 67.7662 9.63851 67.7308 9.58512C67.6593 9.47731 67.6335 9.34547 67.659 9.21861C67.6846 9.09174 67.7594 8.98024 67.867 8.90864C67.9747 8.83703 68.1063 8.81119 68.2329 8.83679C80.7113 11.3727 85.6618 18.9755 87.6225 24.9063C89.7389 31.3098 88.9235 37.0228 88.8876 37.2629C88.869 37.3779 88.8105 37.4827 88.7224 37.5588C88.6342 37.6348 88.5221 37.6773 88.4058 37.6787Z"
        fill="#2E3092"
      />
      <path
        d="M90.8078 50.0674C90.7129 50.0671 90.6201 50.0391 90.5409 49.9869C90.4617 49.9346 90.3993 49.8603 90.3616 49.7731C90.3238 49.6859 90.3123 49.5896 90.3283 49.4959C90.3444 49.4022 90.3873 49.3153 90.4519 49.2457C90.557 49.1343 100.865 37.9015 96.8641 23.2094C96.83 23.0845 96.8468 22.9512 96.9109 22.8387C96.975 22.7263 97.081 22.6439 97.2057 22.6098C97.3304 22.5757 97.4635 22.5925 97.5757 22.6567C97.688 22.7209 97.7702 22.8271 97.8043 22.952C101.951 38.1824 91.2711 49.7976 91.1623 49.914C91.1166 49.9621 91.0616 50.0005 91.0006 50.0269C90.9397 50.0532 90.8741 50.067 90.8078 50.0674Z"
        fill="#2E3092"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.929 21.8467C29.8119 20.9902 31.0288 20.1647 30.8855 19.7625C30.7422 19.3603 29.2794 19.4939 27.2804 20.0236C26.8608 20.1377 26.4144 20.0979 26.0217 19.9113C25.6289 19.7248 25.3155 19.4038 25.1381 19.0063C24.2831 17.1214 23.459 15.9023 23.0575 16.0459C22.656 16.1894 22.7894 17.6548 23.3194 19.6561C23.433 20.0766 23.393 20.524 23.2066 20.9177C23.0202 21.3113 22.6996 21.6254 22.3026 21.8034C20.421 22.6586 19.2041 23.4853 19.3474 23.8863C19.4907 24.2873 20.9535 24.1561 22.9513 23.6252C23.371 23.5111 23.8175 23.5508 24.2105 23.7374C24.6036 23.9239 24.9171 24.2449 25.0948 24.6425C25.9448 26.5287 26.7738 27.7478 27.1803 27.5992C27.5868 27.4507 27.4496 25.9903 26.9196 23.9878C26.8066 23.569 26.8462 23.1235 27.0311 22.7312C27.2161 22.3389 27.5343 22.0254 27.929 21.8467Z"
        fill="#2E3092"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.712 90.4442C125.625 91.5878 127.803 92.0395 128.088 91.4603C128.372 90.8811 126.685 89.4294 124.002 87.8167C123.436 87.4807 123.008 86.9531 122.796 86.3291C122.583 85.7051 122.601 85.0256 122.844 84.4132C123.986 81.4948 124.437 79.3129 123.858 79.0282C123.28 78.7436 121.831 80.433 120.221 83.1211C119.886 83.6883 119.359 84.1168 118.736 84.3294C118.113 84.542 117.435 84.5248 116.824 84.2808C113.91 83.1372 111.732 82.6855 111.448 83.2647C111.164 83.8439 112.85 85.2956 115.534 86.9083C116.1 87.2443 116.528 87.7719 116.74 88.3959C116.952 89.0199 116.935 89.6994 116.691 90.3118C115.55 93.2301 115.099 95.4121 115.677 95.6968C116.255 95.9814 117.705 94.292 119.314 91.6039C119.65 91.0367 120.176 90.6082 120.799 90.3956C121.422 90.183 122.101 90.2002 122.712 90.4442Z"
        fill="#2E3092"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.742 62.0663C124.153 62.1578 125.154 62.0526 125.197 61.7643C125.24 61.4759 124.314 61.0823 122.937 60.7568C122.647 60.6903 122.391 60.5222 122.214 60.2827C122.036 60.0433 121.95 59.7484 121.971 59.4511C122.063 58.0365 121.958 57.0353 121.67 56.9919C121.382 56.9486 120.989 57.8768 120.665 59.2556C120.599 59.5461 120.43 59.8031 120.191 59.9803C119.952 60.1576 119.657 60.2433 119.361 60.2222C117.948 60.1306 116.949 60.237 116.906 60.5254C116.863 60.8138 117.789 61.2061 119.165 61.5316C119.456 61.5984 119.712 61.7668 119.889 62.0064C120.067 62.246 120.152 62.5411 120.132 62.8385C120.04 64.2519 120.145 65.2544 120.433 65.2965C120.721 65.3386 121.114 64.4128 121.437 63.0328C121.504 62.7425 121.673 62.4856 121.912 62.3084C122.151 62.1312 122.445 62.0453 122.742 62.0663Z"
        fill="#2E3092"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.25 103.903C22.6609 103.995 23.6604 103.89 23.7036 103.601C23.7469 103.313 22.8215 102.919 21.4439 102.594C21.1541 102.527 20.8975 102.359 20.7206 102.12C20.5436 101.88 20.4579 101.585 20.479 101.288C20.5704 99.8737 20.4654 98.8724 20.1776 98.8291C19.8897 98.7858 19.4968 99.714 19.1719 101.093C19.1052 101.383 18.9373 101.64 18.6984 101.818C18.4595 101.995 18.1652 102.081 17.8685 102.061C16.4563 101.969 15.4568 102.074 15.4136 102.363C15.3703 102.651 16.2969 103.044 17.6733 103.369C17.9632 103.436 18.2198 103.604 18.3967 103.844C18.5737 104.083 18.6593 104.378 18.6382 104.676C18.5467 106.089 18.6517 107.092 18.9409 107.135C19.23 107.178 19.6204 106.25 19.9453 104.871C20.012 104.581 20.1801 104.323 20.4193 104.146C20.6585 103.969 20.953 103.883 21.25 103.903Z"
        fill="#2E3092"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.3791 5.07439C89.79 5.16598 90.7907 5.06078 90.834 4.77241C90.8772 4.48404 89.9506 4.09047 88.5743 3.76497C88.2843 3.69842 88.0276 3.53029 87.8504 3.29088C87.6733 3.05147 87.5874 2.75654 87.6081 2.45926C87.6995 1.04588 87.5958 0.0446251 87.3067 7.00643e-05C87.0176 -0.0444849 86.6259 0.884982 86.3022 2.26371C86.2359 2.55375 86.0684 2.81055 85.8299 2.98796C85.5914 3.16536 85.2976 3.25174 85.0013 3.23155C83.5904 3.1412 82.5896 3.2464 82.5464 3.53477C82.5031 3.82314 83.4297 4.21671 84.8061 4.54097C85.0961 4.60777 85.3529 4.77615 85.53 5.01576C85.7071 5.25538 85.793 5.55048 85.7722 5.84791C85.6808 7.2613 85.7858 8.26379 86.0737 8.30587C86.3615 8.34795 86.7544 7.42219 87.0793 6.04222C87.1455 5.75229 87.3127 5.49551 87.551 5.31809C87.7892 5.14066 88.0829 5.05425 88.3791 5.07439Z"
        fill="#2E3092"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.1569 30.452C49.569 30.3604 50.5685 30.4656 50.6117 30.754C50.655 31.0423 49.7284 31.4359 48.3521 31.7614C48.0622 31.8282 47.8057 31.9964 47.6285 32.2357C47.4514 32.4751 47.3654 32.7699 47.3859 33.0671C47.4773 34.4817 47.3723 35.483 47.0845 35.5263C46.7966 35.5696 46.4037 34.6414 46.08 33.2627C46.012 32.9733 45.8434 32.7177 45.6043 32.5417C45.3652 32.3657 45.0713 32.2808 44.7753 32.3023C43.3644 32.3926 42.3637 32.2874 42.3217 31.999C42.2797 31.7107 43.2038 31.3183 44.5801 30.9928C44.8702 30.926 45.1269 30.7577 45.3041 30.518C45.4812 30.2784 45.5671 29.9833 45.5463 29.6859C45.4549 28.2725 45.5599 27.27 45.8477 27.2279C46.1356 27.1859 46.5285 28.1116 46.8534 29.4916C46.9216 29.7806 47.0901 30.0358 47.3289 30.2118C47.5677 30.3877 47.8612 30.4729 48.1569 30.452Z"
        fill="#2E3092"
      />
    </svg>
  );
};

export { HandQuickSetupIcon };
