export enum EPreferences {
  IS_BUSINESS_NUMBER_REQUIRED_IN_CUSTOMERS = "IS_BUSINESS_NUMBER_REQUIRED_IN_CUSTOMERS",
  ROUND_PRICING = "ROUND_PRICING",
  System_VAT = "System_VAT",
  DEFUALT_AUTO_DISCOUNT = "DEFUALT_AUTO_DISCOUNT",
  DOCUMENT_AGENT = "DOCUMENT_AGENT",
  DELIVERY_TICKET_SIZE = "DELIVERY_TICKET_SIZE",
  PRODUCTION_TICKET_SIZE = "PRODUCTION_TICKET_SIZE",
  SHOW_PHONE_PREFIX = "SHOW_PHONE_PREFIX",
}
