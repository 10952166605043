const ArrowUpWithCircle = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.96967 9.53033C10.2626 9.82322 10.7374 9.82322 11.0303 9.53033C11.3232 9.23744 11.3232 8.76256 11.0303 8.46967L8.53033 5.96967C8.23744 5.67678 7.76256 5.67678 7.46967 5.96967L4.96967 8.46967C4.67678 8.76256 4.67678 9.23744 4.96967 9.53033C5.26256 9.82322 5.73744 9.82322 6.03033 9.53033L8 7.56066L9.96967 9.53033Z" fill="#464F60" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8ZM2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8Z" fill="#464F60" />
    </svg>
  );
};

export { ArrowUpWithCircle };
