const ChangeIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.16696 1.75L9.5903 3.11502L4.9528 3.10334C2.8703 3.10334 1.16113 4.81252 1.16113 6.90668C1.16113 7.95085 1.58696 8.90167 2.27529 9.59"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.83349 12.2499L4.41016 10.8849L9.04765 10.8966C11.1302 10.8966 12.8393 9.1874 12.8393 7.09323C12.8393 6.04906 12.4135 5.09825 11.7252 4.40991"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.25 7H8.75"
        stroke="#292D32"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const NextArrowIcon = ({ color }: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.16602 10H15.8327M15.8327 10L9.99935 4.16667M15.8327 10L9.99935 15.8333"
        stroke={color || "#344054"}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PrevArrowIcon = ({ color }: any) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.8337 10H4.16699M4.16699 10L10.0003 15.8333M4.16699 10L10.0003 4.16667"
        stroke={color || "#344054"}
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const NotDeliveredIcon = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9 2H9.10001C8.42001 2 7.46 2.4 6.98 2.88L2.88 6.98001C2.4 7.46001 2 8.42001 2 9.10001V14.9C2 15.58 2.4 16.54 2.88 17.02L6.98 21.12C7.46 21.6 8.42001 22 9.10001 22H14.9C15.58 22 16.54 21.6 17.02 21.12L21.12 17.02C21.6 16.54 22 15.58 22 14.9V9.10001C22 8.42001 21.6 7.46001 21.12 6.98001L17.02 2.88C16.54 2.4 15.58 2 14.9 2Z"
        stroke="#e15656"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.5 15.5L15.5 8.5"
        stroke="#e15656"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.5 15.5L8.5 8.5"
        stroke="#e15656"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeliveredIcon = ({ color }: any) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 3L5 8.5L2.5 6"
        stroke={color || "#17B26A"}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const PendingIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.98438 11C8.7458 11 10.9844 8.76142 10.9844 6C10.9844 3.23858 8.7458 1 5.98438 1C3.22295 1 0.984375 3.23858 0.984375 6C0.984375 8.76142 3.22295 11 5.98438 11Z"
        stroke="#FFD700"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.36 7.26502V4.73502C5.36 4.49502 5.26 4.40002 5.005 4.40002H4.355C4.1 4.40002 4 4.49502 4 4.73502V7.26502C4 7.50502 4.1 7.60002 4.355 7.60002H5C5.26 7.60002 5.36 7.50502 5.36 7.26502Z"
        stroke="#FFD700"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7.99953 7.26502V4.73502C7.99953 4.49502 7.89953 4.40002 7.64453 4.40002H6.99953C6.74453 4.40002 6.64453 4.49502 6.64453 4.73502V7.26502C6.64453 7.50502 6.74453 7.60002 6.99953 7.60002H7.64453C7.89953 7.60002 7.99953 7.50502 7.99953 7.26502Z"
        stroke="#FFD700"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CanceledIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 3.5L3.5 8.5M3.5 3.5L8.5 8.5"
        stroke="#f44336"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const WArrowIcon = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5254 4.94167L17.5837 10L12.5254 15.0583"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.41602 10H17.441"
        stroke="white"
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SendIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.74928 11.2501L17.4993 2.50005M8.85559 11.5234L11.0457 17.1551C11.2386 17.6512 11.3351 17.8993 11.4741 17.9717C11.5946 18.0345 11.7381 18.0346 11.8587 17.9719C11.9978 17.8997 12.0946 17.6517 12.2881 17.1558L17.78 3.08272C17.9547 2.63507 18.0421 2.41124 17.9943 2.26822C17.9528 2.14401 17.8553 2.04654 17.7311 2.00504C17.5881 1.95726 17.3643 2.04461 16.9166 2.2193L2.84349 7.71125C2.34759 7.90477 2.09965 8.00153 2.02739 8.14062C1.96475 8.26119 1.96483 8.40474 2.02761 8.52523C2.10004 8.66424 2.3481 8.7607 2.84422 8.95364L8.47589 11.1437C8.5766 11.1829 8.62695 11.2025 8.66935 11.2327C8.70693 11.2595 8.7398 11.2924 8.7666 11.33C8.79685 11.3724 8.81643 11.4227 8.85559 11.5234Z"
        stroke="#2E3092"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const DeleteIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 4.00001V3.46668C10.6667 2.71994 10.6667 2.34657 10.5213 2.06136C10.3935 1.81047 10.1895 1.6065 9.93865 1.47867C9.65344 1.33334 9.28007 1.33334 8.53333 1.33334H7.46667C6.71993 1.33334 6.34656 1.33334 6.06135 1.47867C5.81046 1.6065 5.60649 1.81047 5.47866 2.06136C5.33333 2.34657 5.33333 2.71994 5.33333 3.46668V4.00001M6.66667 7.66668V11M9.33333 7.66668V11M2 4.00001H14M12.6667 4.00001V11.4667C12.6667 12.5868 12.6667 13.1468 12.4487 13.5747C12.2569 13.951 11.951 14.2569 11.5746 14.4487C11.1468 14.6667 10.5868 14.6667 9.46667 14.6667H6.53333C5.41323 14.6667 4.85318 14.6667 4.42535 14.4487C4.04903 14.2569 3.74307 13.951 3.55132 13.5747C3.33333 13.1468 3.33333 12.5868 3.33333 11.4667V4.00001"
        stroke="#344054"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const SaveIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.741 4.89999H4.25767C2.83267 4.89999 1.66602 6.06665 1.66602 7.49165V16.9583C1.66602 18.1667 2.53268 18.6833 3.59101 18.0917L6.86601 16.2666C7.21601 16.075 7.78268 16.075 8.12435 16.2666L11.3993 18.0917C12.4577 18.6833 13.3243 18.1667 13.3243 16.9583V7.49165C13.3327 6.06665 12.166 4.89999 10.741 4.89999Z"
        stroke="#2E3092"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.3327 7.49165V16.9583C13.3327 18.1667 12.466 18.675 11.4077 18.0917L8.13269 16.2666C7.78269 16.075 7.21601 16.075 6.86601 16.2666L3.59101 18.0917C2.53268 18.675 1.66602 18.1667 1.66602 16.9583V7.49165C1.66602 6.06665 2.83267 4.89999 4.25767 4.89999H10.741C12.166 4.89999 13.3327 6.06665 13.3327 7.49165Z"
        stroke="#2E3092"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.3327 4.25831V13.725C18.3327 14.9333 17.466 15.4416 16.4077 14.8583L13.3327 13.1416V7.49165C13.3327 6.06665 12.166 4.89999 10.741 4.89999H6.66602V4.25831C6.66602 2.83331 7.83267 1.66666 9.25767 1.66666H15.741C17.166 1.66666 18.3327 2.83331 18.3327 4.25831Z"
        stroke="#2E3092"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export {
  ChangeIcon,
  PrevArrowIcon,
  NextArrowIcon,
  CanceledIcon,
  DeliveredIcon,
  PendingIcon,
  WArrowIcon,
  SendIcon,
  SaveIcon,
  DeleteIcon,
  NotDeliveredIcon,
};
